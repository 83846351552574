import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LabelInputSmall from "../component/LabelInputSmall";
import Dropdown from "../component/DropDown";
import ReusableButton from "../component/ReusableButton";
import edit from "../assets/edit.svg"
import profile from "../assets/profile.svg"
import Invisible from "../component/Invisible";

export default function ParentsDetails() {
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherOccupation, setMotherOccupation] = useState("");
  const [fathersQualification, setFathersQualification] = useState("");
  const [mothersQualification, setMothersQualification] = useState("");
  const [parentsContact1, setParentsContact1] = useState("");
  const [parentsContact2, setParentsContact2] = useState("");
  const [parentsEmail, setParentsEmail] = useState("");
  const [familyAnnualIncome, setFamilyAnnualIncome] = useState("");
  const [numberOfSiblings, setNumberOfSiblings] = useState("");
  const [nationality, setNationality] = useState("");
  const [parent, setParent] = useState("parent");
  const [fatherImage, setFatherImage] = useState(undefined);
  const [motherImage, setMotherImage] = useState(undefined);
  const [guardianImage, setGuardianImage] = useState(undefined);
  const fatherInputRef = useRef();
  const motherInputRef = useRef();
  const guardianInputRef = useRef();
  const [communicationAddress, setCommunicationAddress] = useState({
    state: "",
    district: "",
    city: "",
    pincode: "",
    streetName: "",
    houseNumber: "",
  });
  const [permanentAddress, setPermanentAddress] = useState({
    state: "",
    district: "",
    city: "",
    pincode: "",
    streetName: "",
    houseNumber: "",
  });
  const [isAddressSame, setIsAddressSame] = useState(false);

  // Dropdown options
  const nationalityOptions = [
    { value: "Indian", option: "Indian" },
    { value: "Other", option: "Other" },
    // ... add more options as needed
  ];

  const stateOptions = [
    // Populate with states
  ];

  const districtOptions = [
    // Populate with districts based on selected state
  ];


  const handleFatherImageUpload = (event) => {
    if (event) {
      setFatherImage(event.target.files[0]);
    }
  };
  const handleMotherImageUpload = (event) => {
    if (event) {
      setMotherImage(event.target.files[0]);
    }
  };
  const handleGuardianImageUpload = (event) => {
    if (event) {
      setGuardianImage(event.target.files[0]);
    }
  };

  const navigate = useNavigate();
  const handleSave = () => {
    navigate("/payment");
  };


  return (
    <div className="mt-10">
      {/* Parent's photo */}
      <div className="flex flex-wrap items-center justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">
        <div className="w-full pl-1">
          <div className={`w-[300px] h-full flex justify-start`}>
            <label
              className="text-[#344054] text-[15px] font-[500]"
              htmlFor={"label"}
            >
              Parent / Guardian<span className="text-[#D92D20]">*</span>
            </label>
          </div>
          <div className="flex gap-6 mt-6 text-[#344054]">
            <div className="flex gap-2">
              <input type="radio" name="parent" value="guardian" onChange={(e) => setParent(e.target.value)} checked={parent === "guardian"} />
              <label htmlFor="parent" className={`${parent !== "guardian" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Guardian</label>
            </div>
            <div className="flex gap-2">
              <input type="radio" name="parent" value="parent" onChange={(e) => setParent(e.target.value)} checked={parent === "parent"} />
              <label htmlFor="parent" className={`${parent !== "parent" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Parent</label>
            </div>
          </div>
          {parent === "parent" ? (
            <div className="flex justify-around mt-10">
              <div className="flex flex-col items-center pt-2 justify-center w-full">
                <div className="relative -top-4">
                  {fatherImage === undefined ? (
                    <img width={100} src={profile} alt="profile" className="w-32 h-32" />
                  ) : (
                    <img
                      src={URL.createObjectURL(fatherImage)}
                      alt="profile"
                      className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                    />
                  )}
                  <button
                    className="absolute right-1 top-3"
                    onClick={() => fatherInputRef.current.click()}
                  >
                    <img src={edit} alt="" />
                    <input
                      type="file"
                      className="hidden"
                      ref={fatherInputRef}
                      onChange={handleFatherImageUpload}
                      accept="image/*"
                    />
                  </button>
                </div>
                <div className="font-semibold">Father's Image<span className="text-[#D92D20]">*</span></div>
              </div>
              <div className="flex flex-col items-center pt-2 justify-center w-full">
                <div className="relative -top-4">
                  {motherImage === undefined ? (
                    <img width={100} src={profile} alt="profile" className="w-32 h-32" />
                  ) : (
                    <img
                      src={URL.createObjectURL(motherImage)}
                      alt="profile"
                      className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                    />
                  )}
                  <button
                    className="absolute right-1 top-3"
                    onClick={() => motherInputRef.current.click()}
                  >
                    <img src={edit} alt="" />
                    <input
                      type="file"
                      className="hidden"
                      ref={motherInputRef}
                      onChange={handleMotherImageUpload}
                      accept="image/*"
                    />
                  </button>
                </div>
                <div className="font-semibold">Mother's Image<span className="text-[#D92D20]">*</span></div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center pt-2 justify-center w-full mt-10">
              <div className="relative -top-4">
                {guardianImage === undefined ? (
                  <img width={100} src={profile} alt="profile" className="w-32 h-32" />
                ) : (
                  <img
                    src={URL.createObjectURL(guardianImage)}
                    alt="profile"
                    className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
                  />
                )}
                <button
                  className="absolute right-1 top-3"
                  onClick={() => guardianInputRef.current.click()}
                >
                  <img src={edit} alt="" />
                  <input
                    type="file"
                    className="hidden"
                    ref={guardianInputRef}
                    onChange={handleGuardianImageUpload}
                    accept="image/*"
                  />
                </button>
              </div>
              <div className="font-semibold">Guardian's Image<span className="text-[#D92D20]">*</span></div>
            </div>
          )}
        </div>

        {parent === "parent" ? (
          <>
            {/* Father's Name */}
            <LabelInputSmall
              mandatory={true}
              label="Father Name"
              type="text"
              placeholder="Enter father’s name"
              value={fatherName}
              onChange={(e) => setFatherName(e.target.value)}
            />

            {/* Mother's Name */}
            <LabelInputSmall
              mandatory={true}
              label="Mother Name"
              type="text"
              placeholder="Enter mother’s name"
              value={motherName}
              onChange={(e) => setMotherName(e.target.value)}
            />

            {/* Father's Occupation */}
            <LabelInputSmall
              mandatory={true}
              label="Father’s Occupation"
              type="text"
              placeholder="Enter father’s occupation"
              value={fatherOccupation}
              onChange={(e) => setFatherOccupation(e.target.value)}
            />

            {/* Mother's Occupation */}
            <LabelInputSmall
              mandatory={true}
              label="Mother’s Occupation"
              type="text"
              placeholder="Enter mother’s occupation"
              value={motherOccupation}
              onChange={(e) => setMotherOccupation(e.target.value)}
            />

            {/* Father's Qualification */}
            <LabelInputSmall
              mandatory={true}
              label="Father’s Qualification"
              type="text"
              placeholder="Enter father’s qualification"
              value={fathersQualification}
              onChange={(e) => setFathersQualification(e.target.value)}
            />

            {/* Mother's Qualification */}
            <LabelInputSmall
              mandatory={true}
              label="Mother’s Qualification"
              type="text"
              placeholder="Enter mother’s qualification"
              value={mothersQualification}
              onChange={(e) => setMothersQualification(e.target.value)}
            />

            {/* Parents Contact Number 1 */}
            <LabelInputSmall
              mandatory={true}
              label="Parent’s Contact Number 1"
              type="text"
              placeholder="+91 0000000000"
              value={parentsContact1}
              onChange={(e) => setParentsContact1(e.target.value)}
            />

            {/* Parents Contact Number 2 */}
            <LabelInputSmall
              mandatory={true}
              label="Parent’s Contact Number 2"
              type="text"
              placeholder="+91 0000000000"
              value={parentsContact2}
              onChange={(e) => setParentsContact2(e.target.value)}
            />

            {/* Parent's Email */}
            <LabelInputSmall
              mandatory={true}
              label="Parent’s Email"
              type="email"
              placeholder="Enter parent’s email"
              value={parentsEmail}
              onChange={(e) => setParentsEmail(e.target.value)}
            />

            {/* Family Annual Income */}
            <LabelInputSmall
              mandatory={true}
              label="Family Annual Income"
              type="text"
              placeholder="Enter family annual income"
              value={familyAnnualIncome}
              onChange={(e) => setFamilyAnnualIncome(e.target.value)}
            />

            {/* Number of Siblings */}
            <LabelInputSmall
              mandatory={true}
              label="Number of Siblings"
              type="text"
              placeholder="Enter number of siblings"
              value={numberOfSiblings}
              onChange={(e) => setNumberOfSiblings(e.target.value)}
            />

            {/* Nationality Dropdown */}
            <Dropdown
              label="Nationality"
              mandatory={true}
              data={nationalityOptions}
              displayValue="value"
              returnValue="option"
              placeholder="Select"
              selectedOption={nationality}
              setSelectedOption={setNationality}
            /></>) : (
          <>
            {/* Father's Name */}
            <LabelInputSmall
              mandatory={true}
              label="Father Name"
              type="text"
              placeholder="Enter father’s name"
              value={fatherName}
              onChange={(e) => setFatherName(e.target.value)}
            />

            {/* Parents Contact Number */}
            <LabelInputSmall
              mandatory={true}
              label="Contact Number"
              type="text"
              placeholder="+91 0000000000"
              value={parentsContact1}
              onChange={(e) => setParentsContact1(e.target.value)}
            />
            <Invisible />
          </>
        )}
      </div>

      <div className="flex flex-wrap items-center justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">
        {/* Communication Address */}
        <div className="flex items-center justify-start w-full ">
          <div className=" pt-10 pl-5 pb-3 text-start text-gray-900 font-medium leading-tight">
            Communication Address
          </div>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">
        {/* State Dropdown */}
        <Dropdown
          label="State"
          mandatory={true}
          data={stateOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select"
          selectedOption={communicationAddress.state}
          setSelectedOption={(value) =>
            setCommunicationAddress({ ...communicationAddress, state: value })
          }
        />

        {/* District Dropdown */}
        <Dropdown
          label="District"
          mandatory={true}
          data={districtOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select"
          selectedOption={communicationAddress.district}
          setSelectedOption={(value) =>
            setCommunicationAddress({
              ...communicationAddress,
              district: value,
            })
          }
        />

        {/* City */}
        <LabelInputSmall
          mandatory={true}
          label="City"
          type="text"
          placeholder="Enter city name"
          value={communicationAddress.city}
          onChange={(e) =>
            setCommunicationAddress({
              ...communicationAddress,
              city: e.target.value,
            })
          }
        />

        {/* Pincode */}
        <LabelInputSmall
          mandatory={true}
          label="Pincode"
          type="text"
          placeholder="000000"
          value={communicationAddress.pincode}
          onChange={(e) =>
            setCommunicationAddress({
              ...communicationAddress,
              pincode: e.target.value,
            })
          }
        />

        {/* Street Name */}
        <LabelInputSmall
          mandatory={true}
          label="Street Name"
          type="text"
          placeholder="Enter street name"
          value={communicationAddress.streetName}
          onChange={(e) =>
            setCommunicationAddress({
              ...communicationAddress,
              streetName: e.target.value,
            })
          }
        />

        {/* House Number */}
        <LabelInputSmall
          mandatory={true}
          label="House Number"
          type="text"
          placeholder="Enter house number"
          value={communicationAddress.houseNumber}
          onChange={(e) =>
            setCommunicationAddress({
              ...communicationAddress,
              houseNumber: e.target.value,
            })
          }
        />
      </div>
      <div className="flex items-center justify-center w-full pt-3 md:justify-start md:px-[4.8rem]">
        <label className="font-medium">
          <input
            type="checkbox"
            className="mx-2 "
            checked={isAddressSame}
            onChange={() => setIsAddressSame(!isAddressSame)}
          />
          Is permanent address same as communication address?
        </label>
      </div>


      {/* Permanent Address (Similar structure as Communication Address) */}
      {/* Only show if 'isAddressSame' is false */}
      {!isAddressSame && (
        <>
          <div className="flex flex-wrap items-center justify-center w-full pt-6 gap-5 px-16 lg:justify-center lg:gap-16">
            {/* Checkbox for Same Address */}

            <div className="flex items-center justify-start w-full ">
              <div className=" pt-10 pb-3 pl-5 text-start text-gray-900 font-medium leading-tight">
                Permanent Address
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">

            <Dropdown
              label="State"
              mandatory={true}
              data={stateOptions}
              displayValue="value"
              returnValue="option"
              placeholder="Select"
              selectedOption={communicationAddress.state}
              setSelectedOption={(value) =>
                setCommunicationAddress({
                  ...communicationAddress,
                  state: value,
                })
              }
            />

            {/* District Dropdown */}
            <Dropdown
              label="District"
              mandatory={true}
              data={districtOptions}
              displayValue="value"
              returnValue="option"
              placeholder="Select"
              selectedOption={permanentAddress.district}
              setSelectedOption={(value) =>
                setPermanentAddress({
                  ...permanentAddress,
                  district: value,
                })
              }
            />

            {/* City */}
            <LabelInputSmall
              mandatory={true}
              label="City"
              type="text"
              placeholder="Enter city name"
              value={permanentAddress.city}
              onChange={(e) =>
                setPermanentAddress({
                  ...permanentAddress,
                  city: e.target.value,
                })
              }
            />

            {/* Pincode */}
            <LabelInputSmall
              mandatory={true}
              label="Pincode"
              type="text"
              placeholder="000000"
              value={permanentAddress.pincode}
              onChange={(e) =>
                setPermanentAddress({
                  ...permanentAddress,
                  pincode: e.target.value,
                })
              }
            />

            {/* Street Name */}
            <LabelInputSmall
              mandatory={true}
              label="Street Name"
              type="text"
              placeholder="Enter street name"
              value={permanentAddress.streetName}
              onChange={(e) =>
                setPermanentAddress({
                  ...permanentAddress,
                  streetName: e.target.value,
                })
              }
            />

            {/* House Number */}
            <LabelInputSmall
              mandatory={true}
              label="House Number"
              type="text"
              placeholder="Enter house number"
              value={permanentAddress.houseNumber}
              onChange={(e) =>
                setPermanentAddress({
                  ...permanentAddress,
                  houseNumber: e.target.value,
                })
              }
            />
          </div>
        </>
      )}

      <div className="h-5 lg:h-10"></div>
      <div className="flex items-center mb-7 gap-2  lg:gap-8 justify-center w-full ">
        <Link to={"/academics"}>
          <ReusableButton
            text="Back"
            width="140px"
            bgColor="#D92D20"
            height="40px"
            textColor="#ffffff"
            borderColor="#D92D20"
            pxPadding="8px"
          />
        </Link>
        <ReusableButton
          text="Save & Continue"
          onClick={handleSave}
          width="170px"
          bgColor="#1849A9"
          height="40px"
          textColor="#ffffff"
          borderColor="#1849A9"
          pxPadding="8px"
        />
        <div className="h-2 lg:h-20"></div>
      </div>
    </div>
  );
}
