import axios from "axios";

const Common = {
  community: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllCommunity`
      );
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  nationality: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllNationality`
      );
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  state: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllState`
      );
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  district: async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllDistrict/${id}`
      );
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  course: async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllCourse/${id}`
      );
      console.log("response", response);
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  branch: async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllBranch/${id}`
      );
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  courseCategory: async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllCourseCategory`
      );
      return response.data.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  sendOtp: async (mobileNumber) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}enquiry/sendOtp`,
        { mobileNumber }
      );
      return response.data; // Return the response data
    } catch (error) {
      alert(error.response && error.response.data.message);
      return error.response && error.response.data;
    }
  },
  verifyOtp: async (mobileNumber, mobileOtp) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}enquiry/verifyOtp`,
        { mobileNumber, mobileOtp }
      );
      return response.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  createEnquiry: async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}enquiry/createEnquiry`,
        data
      );
      return response; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
  allReligion: async (params) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}dropDown/findAllReligion`,
      );
      return response.data; // Return the response data
    } catch (error) {
      return error.response && error.response.data;
    }
  },
};

export default Common;
