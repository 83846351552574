import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../component/DropDown";
import GenderInput from "../component/GenderInput";
import LabelInputSmall from "../component/LabelInputSmall";
import ReusableButton from "../component/ReusableButton";
import MobileNumberInput from "../component/MoblieNumberInput";
import DateInput from "../component/DateInput";
import Invisible from "../component/Invisible";
import UploadButton from "../component/UploadButton";
import edit from "../assets/edit.svg"
import profile from "../assets/profile.svg"

export default function PersonalInformation() {

  const studentImageRef = useRef();

  const [studentName, setStudentName] = useState("");
  const [gender, setGender] = useState("");
  const [selectedCourseCategory, setSelectedCourseCategory] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [residentialType, setResidentialType] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [secondaryNumber, setSecondaryNumber] = useState("");
  const [dob, setDor] = useState("");
  const [motherTongue, setMotherTongue] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [quotaInformations, setSelectedQuotaInformations] = useState("");
  const [modeOfAdmission, setSelectedModeOfAdmission] = useState("");
  const [dateOfAdmission, setSelectedDateOfAdmission] = useState("");
  const [studentMailId, setStudentMailId] = useState("");
  const [firstGraduation, setFirstGraduation] = useState("");
  const [specialReservation, setSpecialReservation] = useState("");
  const [typeOfReservation, setTypeOfReservation] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [extraCircularActivities, setExtraCircularActivities] = useState("");
  const [boardingPoint, setboardingPoint] = useState("");
  const [foodHabit, setFoodHabit] = useState("");
  const [differentlyAbled, setDifferentlyAbled] = useState("");

  // states for file-names
  const [studentSignatureInput, setStudentSignatureInput] = useState("");
  const [handWritingInput, setHandWritingInput] = useState("");

  // states for file-data
  const [studentImage, setStudentImage] = useState(undefined);
  const [studentSignatureFile, setStudentSignatureFile] = useState(null);
  const [handWritingFile, setHandWritingFile] = useState(null);

  // drop-down data
  const courseCategoryOptions = [
    { value: "UG", option: "UG" },
    { value: "PG", option: "PG" },
    { value: "Diploma", option: "Diploma" },
  ];
  const courseOptions = [
    { value: "B.E", option: "B.E" },
    { value: "B.Tech", option: "B.Tech" },
    { value: "B.Sc", option: "B.Sc" },
    { value: "B.A", option: "B.A" },
    { value: "B.Com", option: "B.Com" },
    { value: "BBA", option: "BBA" },
  ];
  const branchOptions = [
    { value: "CSE", option: "CSE" },
    { value: "ECE", option: "ECE" },
    { value: "EEE", option: "EEE" },
    { value: "Civil", option: "Civil" },
    { value: "Mechanical", option: "Mechanical" },
    { value: "IT", option: "IT" },
  ];
  const batchOptions = [
    { value: "2023", option: "2023-2024" },
    { value: "2024", option: "2024-2025" },
    { value: "2025", option: "2025-2026" },
    { value: "2026", option: "2026-2027" },
    { value: "2027", option: "2027-2028" },
    { value: "2028", option: "2028-2029" },
    { value: "2029", option: "2029-2030" },
    { value: "2030", option: "2030-2031" },
  ];
  const nationalityOptions = [
    { value: "Indian", option: "Indian" },
    { value: "Others", option: "Others" },
  ];
  const religionOptions = [
    { value: "Hindu", option: "Hindu" },
    { value: "Muslim", option: "Muslim" },
    { value: "Christian", option: "Christian" },
    { value: "Others", option: "Others" },
  ];
  const casteOptions = [
    { value: "BC", option: "BC" },
    { value: "MBC", option: "MBC" },
    { value: "OC", option: "OC" },
    { value: "SC", option: "SC" },
    { value: "ST", option: "ST" },
  ];
  const quotaInformationsOptions = [
    { value: "Yes", option: "Yes" },
    { value: "No", option: "No" },
  ];
  const modeOfAdmissionOptions = [
    { value: "Single Window", option: "Single Window" },
    { value: "Management", option: "Management" },
    { value: "NRI", option: "NRI" },
  ];
  const typeOfReservationOptions = [
    { value: "Sports", option: "Sports" },
    { value: "Ex-Servicemen", option: "Ex-Servicemen" },
    { value: "Differently Abled", option: "Differently Abled" },
    { value: "Others", option: "Others" },
  ];
  const bloodGroupOptions = [
    { value: "A+", option: "A+" },
    { value: "A-", option: "A-" },
    { value: "B+", option: "B+" },
    { value: "B-", option: "B-" },
    { value: "AB+", option: "AB+" },
    { value: "AB-", option: "AB-" },
    { value: "O+", option: "O+" },
    { value: "O-", option: "O-" },
  ];
  const specialReservationOptions = [
    { value: "Yes", option: "Yes" },
    { value: "No", option: "No" },
  ];
  // const firstGraduationOptions = [
  //   { value: "Yes", option: "Yes" },
  //   { value: "No", option: "No" },
  // ];

  // const differentlyAbledOptions = [
  //   { value: "Yes", option: "Yes" },
  //   { value: "No", option: "No" },
  // ];
  // const foodHabitOptions = [
  //   { value: "Veg", option: "Veg" },
  //   { value: "Non-Veg", option: "Non-Veg" },
  //   { value: "Others", option: "Others" },
  // ];

  const handleGenderChange = (newGender) => {
    setGender(newGender);
  };

  const handleStudentImage = (event) => {
    if (event) {
      setStudentImage(event.target.files[0]);
    }
  };

  const navigate = useNavigate();
  const handleSave = () => {
    navigate("/bank");
  };

  return (
    <div className="mt-10 ">
      <div className="flex flex-wrap items-start justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">
        <div className="flex flex-col items-center pt-2 justify-center w-full mt-10">
          <div className="relative -top-4">
            {studentImage === undefined ? (
              <img width={100} src={profile} alt="profile" className="w-32 h-32" />
            ) : (
              <img
                src={URL.createObjectURL(studentImage)}
                alt="profile"
                className="rounded-full w-32 h-32 object-cover border-2 shadow-lg"
              />
            )}
            <button
              className="absolute right-1 top-3"
              onClick={() => studentImageRef.current.click()}
            >
              <img src={edit} alt="" />
              <input
                type="file"
                className="hidden"
                ref={studentImageRef}
                onChange={handleStudentImage}
                accept="image/*"
              />
            </button>
          </div>
        </div>
        {/* Student Name */}
        <LabelInputSmall
          mandatory={true}
          label="Student Name"
          type="text"
          placeholder="Enter student name"
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
        />

        {/* DOB */}
        <DateInput
          label="Date of Birth"
          mandatory={true}
          value={dob}
          setDate={setDor}
          date={dob}
          onChange={(e) => setDor(e.target.value)}
          placeholder="dd/mm/yyyy"
        />

        {/* Gender */}
        <GenderInput
          mandatory={true}
          label="Gender"
          value={gender}
          onChange={handleGenderChange}
        />

        {/* Course Category */}
        <Dropdown
          label="Course Category"
          mandatory={true}
          data={courseCategoryOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select course category"
          selectedOption={selectedCourseCategory}
          setSelectedOption={setSelectedCourseCategory}
        />

        {/* Course */}
        <Dropdown
          label="Course"
          mandatory={true}
          data={courseOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select course"
          selectedOption={selectedCourse}
          setSelectedOption={setSelectedCourse}
        />

        {/* Branch */}
        <Dropdown
          label="Branch"
          mandatory={true}
          data={branchOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select branch"
          selectedOption={selectedBranch}
          setSelectedOption={setSelectedBranch}
        />

        {/* Batch */}
        <Dropdown
          label="Batch"
          mandatory={true}
          data={batchOptions}
          displayValue="option"
          returnValue="value"
          placeholder="Select batch"
          selectedOption={selectedBatch}
          setSelectedOption={setSelectedBatch}
        />

        {/* Blood Group */}
        <Dropdown
          label="Blood Group"
          mandatory={true}
          data={bloodGroupOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select blood group"
          selectedOption={bloodGroup}
          setSelectedOption={setBloodGroup}
        />

        {/* First Graduation */}
        <div className="flex flex-col">
          <div className={`w-[300px] h-full flex justify-start`}>
            <label
              className="text-[#344054] text-[15px] font-[500]"
              htmlFor={"label"}
            >
              First Graduation<span className="text-[#D92D20]">*</span>
            </label>
          </div>
          <div className="flex gap-14 mt-4 text-[#344054]">
            <div className="flex gap-2">
              <input type="radio" name="first_graduation" value="yes" onChange={(e) => setFirstGraduation(e.target.value)} checked={firstGraduation === "yes"} />
              <label htmlFor="first_graduation" className={`${firstGraduation !== "yes" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Yes</label>
            </div>
            <div className="flex gap-2">
              <input type="radio" name="first_graduation" value="no" onChange={(e) => setFirstGraduation(e.target.value)} checked={firstGraduation === "no"} />
              <label htmlFor="first_graduation" className={`${firstGraduation !== "no" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>No</label>
            </div>
          </div>
        </div>

        {/* Special Reservation */}
        <Dropdown
          label="Special Reservation"
          mandatory={true}
          data={specialReservationOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select special reservation"
          selectedOption={specialReservation}
          setSelectedOption={setSpecialReservation}
        />

        {/* Mobile Number */}
        <MobileNumberInput
          label="Whatsapp Number"
          phoneNumber={mobileNumber}
          setPhoneNumber={setMobileNumber}
        />

        {/* Secondary Number */}
        <MobileNumberInput
          mandate={false}
          label="Secondary Number"
          phoneNumber={secondaryNumber}
          setPhoneNumber={setSecondaryNumber}
        />

        {/* Residential Type */}
        <div className="flex flex-col">
          <div className={`w-[300px] h-full flex justify-start`}>
            <label
              className="text-[#344054] text-[15px] font-[500]"
              htmlFor={"label"}
            >
              Residential type<span className="text-[#D92D20]">*</span>
            </label>
          </div>
          <div className="flex gap-14 mt-4 text-[#344054]">
            <div className="flex gap-2">
              <input type="radio" name="residential_type" value="hosteler" onChange={(e) => setResidentialType(e.target.value)} checked={residentialType === "hosteler"} />
              <label htmlFor="residential_type" className={`${residentialType !== "hosteler" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Hosteler</label>
            </div>
            <div className="flex gap-2">
              <input type="radio" name="residential_type" value="day_scholar" onChange={(e) => setResidentialType(e.target.value)} checked={residentialType === "day_scholar"} />
              <label htmlFor="residential_type" className={`${residentialType !== "day_scholar" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Day Scholar</label>
            </div>
          </div>
        </div>

        {/* Boarding Point */}
        {residentialType === "day_scholar" && (
          <LabelInputSmall
            label="Boarding Point (Day scholar only)"
            type="text"
            placeholder="Enter boarding point"
            value={boardingPoint}
            onChange={(e) => setboardingPoint(e.target.value)}
          />
        )}

        {/* Mother Tongue */}
        <LabelInputSmall
          mandatory={true}
          label="Mother Tongue"
          type="text"
          placeholder="Enter mother tongue"
          value={motherTongue}
          onChange={(e) => setMotherTongue(e.target.value)}
        />

        {/* Nationality */}
        <Dropdown
          label="Nationality"
          mandatory={true}
          data={nationalityOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select nationality"
          selectedOption={selectedNationality}
          setSelectedOption={setSelectedNationality}
        />

        {/* Religion */}
        <Dropdown
          label="Religion"
          mandatory={true}
          data={religionOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select religion"
          selectedOption={selectedReligion}
          setSelectedOption={setSelectedReligion}
        />

        {/* Caste */}
        <Dropdown
          label="Caste"
          mandatory={true}
          data={casteOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select caste"
          selectedOption={selectedCaste}
          setSelectedOption={setSelectedCaste}
        />

        {/* Quota Information */}
        <Dropdown
          label="Quota Information"
          mandatory={true}
          data={quotaInformationsOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select quota information"
          selectedOption={quotaInformations}
          setSelectedOption={setSelectedQuotaInformations}
        />

        {/* Mode of Admission */}
        <Dropdown
          label="Mode of Admission"
          mandatory={true}
          data={modeOfAdmissionOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select mode of admission"
          selectedOption={modeOfAdmission}
          setSelectedOption={setSelectedModeOfAdmission}
        />

        {/* Type of Reservation */}
        <Dropdown
          label="Type of Reservation"
          mandatory={true}
          data={typeOfReservationOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select type of reservation"
          selectedOption={typeOfReservation}
          setSelectedOption={setTypeOfReservation}
        />
        {/* Date of Admission */}
        <DateInput
          label="Date of Admission"
          mandatory={true}
          value={dateOfAdmission}
          setDate={setSelectedDateOfAdmission}
          date={dateOfAdmission}
          onChange={(e) => setSelectedDateOfAdmission(e.target.value)}
          placeholder="dd/mm/yyyy"
        />

        {/* Student Mail ID */}
        <LabelInputSmall
          mandatory={true}
          label="Student Email"
          type="email"
          placeholder="Enter student email"
          value={studentMailId}
          onChange={(e) => setStudentMailId(e.target.value)}
        />

        {/* Aadhar Number */}
        <LabelInputSmall
          mandatory={true}
          label="Aadhar Number"
          type="text"
          placeholder="Enter Aadhar number"
          value={aadharNumber}
          onChange={(e) => setAadharNumber(e.target.value)}
        />

        {/* PAN Number */}
        <LabelInputSmall
          mandatory={true}
          label="PAN Number"
          type="text"
          placeholder="Enter PAN number"
          value={panNumber}
          onChange={(e) => setPanNumber(e.target.value)}
        />

        {/* Passport Number */}
        <LabelInputSmall
          mandatory={true}
          label="Passport Number"
          type="text"
          placeholder="Enter passport number"
          value={passportNumber}
          onChange={(e) => setPassportNumber(e.target.value)}
        />

        {/* Extra-Curricular Activities */}
        <LabelInputSmall
          mandatory={true}
          label="Extra-Curricular Activities"
          type="text"
          placeholder="Enter activities"
          value={extraCircularActivities}
          onChange={(e) => setExtraCircularActivities(e.target.value)}
        />

        {/* Food Habit */}
        <div className="flex flex-col">
          <div className={`w-[300px] h-full flex justify-start`}>
            <label
              className="text-[#344054] text-[15px] font-[500]"
              htmlFor={"label"}
            >
              Food Habit<span className="text-[#D92D20]">*</span>
            </label>
          </div>
          <div className="flex gap-14 mt-4 text-[#344054]">
            <div className="flex gap-2">
              <input type="radio" name="food_habit" value="veg" onChange={(e) => setFoodHabit(e.target.value)} checked={foodHabit === "veg"} />
              <label htmlFor="food_habit" className={`${foodHabit !== "veg" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Veg</label>
            </div>
            <div className="flex gap-2">
              <input type="radio" name="food_habit" value="non_veg" onChange={(e) => setFoodHabit(e.target.value)} checked={foodHabit === "non_veg"} />
              <label htmlFor="food_habit" className={`${foodHabit !== "non_veg" ? "text-[#667085]" : "text-[#101828]"} font-semibold`}>Non-Veg</label>
            </div>
          </div>
        </div>

        {/* Differently Abled */}
        <LabelInputSmall
          label="Differently abled Person"
          type="text"
          placeholder="Enter if there is any disability"
          value={differentlyAbled}
          onChange={(e) => setDifferentlyAbled(e.target.value)}
        />

        {/* Student Signature */}
        <UploadButton
          mandatory
          label={"Student’s Signature"}
          inputValue={studentSignatureInput}
          setInputValue={setStudentSignatureInput}
          selectedFile={studentSignatureFile}
          setSelectedFile={setStudentSignatureFile}
        />

        {/* Student Handwriting */}
        <UploadButton
          mandatory
          label={"Student’s Hand Writing"}
          inputValue={handWritingInput}
          setInputValue={setHandWritingInput}
          selectedFile={handWritingFile}
          setSelectedFile={setHandWritingFile}
        />

        {residentialType === "day_scholar" && (
          <>
            <Invisible />
            <Invisible />
          </>
        )}
        <div className="flex items-center justify-center w-full ">
          <ReusableButton
            text="Save & Continue"
            onClick={handleSave}
            width="170px"
            bgColor="#1849A9"
            height="40px"
            textColor="#ffffff"
            borderColor="#1849A9"
            pxPadding="8px"
          />
        </div>
        <div className="h-2 lg:h-0"></div>
      </div>
    </div>
  );
}