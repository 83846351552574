export default function MobileNumberInput({
  width = "300px",
  height,
  label = "Mobile Number",
  phoneNumber,
  setPhoneNumber,
  mandate,
  widthFull,
  error, readOnly,
  smallPlaceholder,
  disabled,
  ...others
}) {
  if (!width) {
    width = "300px"
  }
  // width = `${width}px`
  if (widthFull) {
    width = "100%"
  }
  if (!height) {
    height = "40"
  } if (mandate === undefined) {
    mandate = true
  }


  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Remove all non-digit characters and split the input into groups of 5 digits
    const cleanedInput = inputValue.replace(/\D/g, "").slice(0, 10);
    const formattedInput = cleanedInput.replace(/(\d{5})/, "$1 ");
    setPhoneNumber(formattedInput.trim());
  };

  return (
    <div className="flex relative flex-col">
      <span className={`${smallPlaceholder && "text-[14px] pb-[2px]"} text-[15px] pb-[4px] leading-5 text-[#344054] font-medium`}>
        {label}{mandate && (<span className="text-[#D92D20]">*</span>)}
      </span>
      <div className={`relative flex items-center justify-center w-[${width}] h-full mb-2`}>
        <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-textBlack font-semibold">
          +91
        </span>
        <input
          type="tel" // Use type "tel" for phone numbers
          value={phoneNumber}
          readOnly={readOnly}
          onChange={handleInputChange}
          disabled={disabled}
          className={`w-[${width}] h-[${height}px]  text-textBlack placeholder-textGray  ${readOnly && "cursor-default"}  spin-button-none rounded-[8px] border ${error ? "border-[#F04438]" : "border-inputBorderGray"}   pl-[40px]    outline-none ${disabled && "bg-[#EEEEEE]"} ${readOnly ? "cursor-default" : "focus:border-[#1849A9]"} placeholder-gray-400  text-black pb-[1px] font-normal`}
          placeholder="00000 00000"
          {...others}
        />
      </div>
      {
        error && (
          <div className=" absolute -bottom-3 font-normal text-[#F04438] text-[13px] text-left">
            {error}
          </div>
        )
      }
    </div>
  );
}
