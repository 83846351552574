import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import link1 from "../assets/progressBar/link1.svg";
import link2 from "../assets/progressBar/link2.svg";
import link3 from "../assets/progressBar/link3.svg";
import link4 from "../assets/progressBar/link4.svg";
import link5 from "../assets/progressBar/link5.svg";
import link2_active from "../assets/progressBar/link2active.svg";
import link3_active from "../assets/progressBar/link3active.svg";
import link4_active from "../assets/progressBar/link4active.svg";
import link5_active from "../assets/progressBar/link5active.svg";

export default function ProgressBar() {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const [pageCounter, setPageCounter] = useState(0);

    useEffect(() => {
        if (location === "/personal") setPageCounter(0);
        else if (location === "/bank") setPageCounter(1);
        else if (location === "/academics") setPageCounter(2);
        else if (location === "/parent") setPageCounter(3);
        else if (location === "/Payment") setPageCounter(4);
    }, [location]);

    const buttons = [
        {
            id: 1,
            img: <img src={link1} alt="icon" className="h-6 w-6" />,
            img_active: <img src={link1} alt="icon" className="h-6 w-6" />,
            link: "personal",
            text: "Personal Information"
        },
        {
            id: 2,
            img: <img src={link2} alt="icon" className="h-6 w-6" />,
            img_active: <img src={link2_active} alt="icon" className="h-6 w-6" />,
            link: "bank",
            text: "Bank Details"
        },
        {
            id: 3,
            img: <img src={link3} alt="icon" className="h-6 w-6" />,
            img_active: <img src={link3_active} alt="icon" className="h-6 w-6" />,
            link: "academics",
            text: "Academics Details"
        },
        {
            id: 4,
            img: <img src={link4} alt="icon" className="h-6 w-6" />,
            img_active: <img src={link4_active} alt="icon" className="h-6 w-6" />,
            link: "parent",
            text: "Parent's Details"
        },
        {
            id: 5,
            img: <img src={link5} alt="icon" className="h-6 w-6" />,
            img_active: <img src={link5_active} alt="icon" className="h-6  w-6" />,
            link: "Payment",
            text: "Payment Gateway"
        },
    ];

    return (
        <div className="flex py-12 md:px-12">
            {buttons.map((button, index) => {
                return (
                    <div className={`flex justify-between items-center mb-12 ${index !== 4 ? "w-full" : "w-1/4"}`} >
                        <div key={button.text} id="progress" className="relative flex justify-center">
                            {index >= pageCounter + 1 ? (
                                <button className={`${index >= pageCounter + 1 ? "bg-white" : "bg-primary-blue text-white"} border-2 border-primary-blue px-4 py-4 rounded-full`} onClick={() => navigate(`/${button.link}`)}>{button.img}</button>
                            ) : (
                                <button className={`${index >= pageCounter + 1 ? "bg-white" : "bg-primary-blue text-white"} border-2 border-primary-blue px-4 py-4 rounded-full`} onClick={() => navigate(`/${button.link}`)}>{button.img_active}</button>
                            )
                            }
                            <p className="font-semibold absolute top-[4.4rem] md:whitespace-nowrap">{button.text}</p>
                        </div>
                        {index !== 4 && (
                            <div className={`flex-auto border-y-2 border-primary-blue h-3 transition duration-500 ${index >= pageCounter ? "" : "bg-primary-blue"}`}></div>
                        )}
                    </div>
                )
            })}
        </div >
    )
}
