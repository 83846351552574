import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../component/DropDown";
import LabelInputSmall from "../component/LabelInputSmall";
import ReusableButton from "../component/ReusableButton";
import Invisible from "../component/Invisible";

export default function BankDetails() {
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [micrCode, setMicrCode] = useState("");

  const indianBanks = [
    { value: "State Bank of India", option: "State Bank of India" },
    { value: "HDFC Bank", option: "HDFC Bank" },
    { value: "ICICI Bank", option: "ICICI Bank" },
    { value: "Punjab National Bank", option: "Punjab National Bank" },
    { value: "Axis Bank", option: "Axis Bank" },
    { value: "Bank of Baroda", option: "Bank of Baroda" },
    // Add more banks as needed
  ];

  const navigate = useNavigate();
  const handleSave = () => {
    navigate("/academics");
  };

  return (
    <div className="mt-10">
      <div className="flex flex-wrap items-center justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">
        {/* Bank Account Number */}
        <LabelInputSmall
          mandatory={true}
          label="Bank Account Number"
          type="text"
          placeholder="Enter bank account number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />

        {/* Bank Name */}
        <Dropdown
          label="Bank Name"
          mandatory={true}
          data={indianBanks}
          displayValue="value"
          returnValue="option"
          placeholder="Select bank name"
          selectedOption={bankName}
          setSelectedOption={setBankName}
        />

        {/* Branch */}
        <LabelInputSmall
          mandatory={true}
          label="Branch"
          type="text"
          placeholder="Enter branch"
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
        />

        {/* IFSC Code */}
        <LabelInputSmall
          mandatory={true}
          label="IFSC Code"
          type="text"
          placeholder="Enter IFSC code"
          value={ifscCode}
          onChange={(e) => setIfscCode(e.target.value)}
        />

        {/* MICR Code */}
        <LabelInputSmall
          mandatory={true}
          label="MICR Code"
          type="text"
          placeholder="Enter MICR code"
          value={micrCode}
          onChange={(e) => setMicrCode(e.target.value)}
        />
        <Invisible />

        <div className="flex items-center   gap-3 lg:gap-8 justify-center w-full ">
          <Link to={"/personal"}>
            <ReusableButton
              text="Back"
              width="140px"
              bgColor="#D92D20"
              height="40px"
              textColor="#ffffff"
              borderColor="#D92D20"
              pxPadding="8px"
            />
          </Link>
          <ReusableButton
            text="Save & Continue"
            onClick={handleSave}
            width="170px"
            bgColor="#1849A9"
            height="40px"
            textColor="#ffffff"
            borderColor="#1849A9"
            pxPadding="8px"
          />
        </div>
        <div className="h-2 lg:h-0 bg-[#ff3434]"></div>
      </div>
    </div>
  );
}
