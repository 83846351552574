import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReusableButton from "../component/ReusableButton";
import collage_logo from "../assets/collegeNameBannerWeb.svg";
import personal from "../assets/personal.svg";
import bank from "../assets/bank.svg";
import academics from "../assets/academics.svg";
import parents from "../assets/parents.svg";
import payment from "../assets/payment.svg";
import ProgressBar from "../component/ProgressBar";

export default function Layout({ children }) {
  const [showModal, setShowModal] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  const contentForModal = [
    {
      title: "Name",
      content: "Elisa",
    },
    {
      title: "Course",
      content: "B.E",
    },
    {
      title: "Gender",
      content: "Female",
    },
    {
      title: "Branch",
      content: "Computer Science",
    },
    {
      title: "Date of Birth",
      content: "07/05/2003",
    },
    {
      title: "Batch",
      content: "2023 - 2027",
    },
    {
      title: "Phone Number",
      content: "1234567890",
    },
    {
      title: "Residential Type",
      content: "Day Scholar",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      if (showModal) {
        window.scrollTo(0, 0);
      }
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showModal, location]);
  return (
    <>
      <div className="relative flex flex-col items-center justify-center w-full h-full ">
        <div className=" bg-[#1849A9] w-full h-[100px] lg:h-[150px] flex justify-center items-center ">
          <img src={collage_logo} alt="" />
        </div>
        <div className="w-full max-w-[1200px] ">
          <div className="w-full h-full ">
            <div className="pb-3 text-2xl font-bold text-center text-gray-900 pt-2  lg:pt-7">
              <div className="text-red-600  lg:text-4xl font-bold">
                Application Form 2023 - 2027
              </div>
              <div className="text-center text-blue-800 text-[16px]  lg:text-xl font-bold  mt-0 lg:mt-2">
                Application ID - A0001
              </div>
            </div>
          </div>
          <ProgressBar />
          <div className=" w-full flex justify-center items-center ">
            <div className=" w-[96%]  lg:w-full mb-10 border border-gray-200 shadow-2xl rounded-xl ">
              <div className="">
                <div className="flex items-center justify-center w-full   pl-5 lg:pl-[70px] mt-10 ">
                  <div className="flex items-center justify-start w-full gap-4 ">
                    <div>
                      {activePage === "/personal" ? (
                        <>
                          <img src={personal} alt="" />
                        </>
                      ) : activePage === "/bank" ? (
                        <>
                          <img src={bank} alt="" />
                        </>
                      ) : activePage === "/academics" ? (
                        <>
                          <img src={academics} alt="" />
                        </>
                      ) : activePage === "/parent" ? (
                        <>
                          <img src={parents} alt="" />
                        </>
                      ) : activePage === "/payment" && (
                        <>
                          <img src={payment} alt="" />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-start mt-3 w-full pl-8 lg:pl-[70px]">
                  <div className="text-gray-900 text-xl font-semibold leading-7 text-start">
                    {activePage === "/personal" ? (
                      <>Personal Information</>
                    ) : activePage === "/bank" ? (
                      <>Bank details</>
                    ) : activePage === "/academics" ? (
                      <>Academics Information</>
                    ) : activePage === "/parent" ? (
                      <>Parent's Details</>
                    ) : activePage === "/payment" ? (
                      <>Payment</>
                    ) : (
                      <>Personal Information</>
                    )}
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>

        </div>
        {showModal && (
          <div className="fixed inset-0 z-50 ">
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute flex justify-center items-center  inset-0 bg-gray-800 bg-opacity-[0.7]">
                  <div className="h-auto  pb-10 bg-white relative rounded-lg shadow-lg   w-[350px]  lg:w-[800px]">
                    <div className="flex items-center w-full "></div>
                    <div className="flex flex-col items-center justify-center w-full ">
                      <div className=" bg-[#1849A9] rounded-t-lg w-full h-[100px] lg:h-[120px] flex justify-center items-center ">
                        <img src={collage_logo} alt="" />
                      </div>
                      <div className="w-full max-w-[1200px] ">
                        <div className="w-full h-full ">
                          <div className="pt-2 pb-3 text-2xl font-bold text-center text-gray-900">
                            <div className="text-red-600 text-[22px] lg:text-3xl font-bold">
                              Application Form 2023 - 2027
                            </div>
                            <div className="text-center text-blue-800 text-[15px] lg:text-[18px] font-bold ">
                              Application ID - A0001
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center justify-center w-full mt-1  lg:mt-5">
                        {contentForModal.map((item) => (
                          <div className="flex items-center justify-between w-[370px] gap-1 py-3 pl-12">
                            <div className="flex justify-start w-full ic">

                              <div className="text-gray-500 lg:text-[17px]  text-[15px] font-semibold leading-normal">
                                {item.title}
                              </div>
                            </div>
                            <div className="flex items-center justify-start w-full ">

                              <div className="text-black lg:text-[17px]  text-[15px] font-semibold leading-normal">
                                : {item.content}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex items-center justify-center w-full  lg:pt-8 pt-4 ">
                        <button
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          <ReusableButton
                            text="Proceed"
                            width="170px"
                            bgColor="#1849A9"
                            height="40px"
                            textColor="#ffffff"
                            borderColor="#1849A9"
                            pxPadding="8px"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
