import avatar from "../assets/Avatar.png";
import thankYou from "../assets/thank you.png";
import ReusableButton from "../component/ReusableButton";
import { useEffect, useState } from "react";

export default function PaymentGateway() {
  const [showModal, setShowModal] = useState(false);

  const intro = [
    {
      title: "Name",
      content: "Elisa",
    },
    {
      title: "Gender",
      content: "Female",
    },
    {
      title: "Phone Number",
      content: "1234567890",
    },
    {
      title: "Course",
      content: "B.E",
    },
    {
      title: "Branch",
      content: "Computer Science",
    },
    {
      title: "Batch",
      content: "2023 - 2027",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (showModal) {
        window.scrollTo(0, 0);
      }
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showModal]);
  return (
    <div className="relative ">
      <div className="relative flex flex-col ">
        <div className="  lg:flex-row flex-col mt-10 flex items-center justify-center w-full  gap-4 lg:gap-10 ">
          <div className="">
            <img className=" w-40 lg:w-52" src={avatar} alt="" />{" "}
          </div>
          <div className="my-1 pl-4 lg:pl-0 lg:my-10 ">
            {intro.map((item) => (
              <div className="flex items-center justify-start ml-2 lg:ml-28 pt-2">
                <div className="text-gray-500 text-[15px] lg:text-xl  w-[150px] lg:w-[200px]  py-1 font-semibold leading-normal">
                  {item.title}
                </div>

                <div className="text-black text-[15px] lg:text-lg font-bold leading-normal">
                  : {item.content}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className=" my-2 lg:my-10 text-center px-5
 ">
          <span className="text-gray-500  text-sm  lg:text-xl font-normal leading-tight">
            Secure your college registration now! Pay just{" "}
          </span>
          <span className="text-gray-900  text-sm  lg:text-xl font-bold leading-tight">
            ₹100
          </span>
          <span className="text-gray-500  text-sm  lg:text-xl font-normal leading-tight">
            {" "}
            within 30 minutes to ensure your spot.{" "}
          </span>
        </div>
      </div>

      <div className="flex items-center mt-3 justify-center w-full ">
        <button
          onClick={() => {
            setShowModal(true);
          }}
        >
          <ReusableButton
            text="Pay Now"
            width="170px"
            bgColor="#1849A9"
            height="40px"
            textColor="#ffffff"
            borderColor="#1849A9"
            pxPadding="8px"
          />
        </button>
      </div>
      <div className="h-10 lg:h-10"></div>
      {showModal && (
        <div className="fixed inset-0 z-50 ">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute flex justify-center items-center  inset-0 bg-gray-700 bg-opacity-[0.6]">
                <div className="h-[350px] bg-white relative rounded-lg   w-[350px] lg:w-[510px]">
                  <div className="flex items-center justify-center w-full py-16 ">
                    <img src={thankYou} alt="" />
                  </div>
                  <div className="flex items-center justify-center w-full ">
                    <div className="w-[350px] h-[68.59px] text-center ">
                      <div className="flex flex-col gap-3 text-gray-500 text-[18px] font-bold leading-normal">
                        <div>Registration Completed</div>
                        <div>
                          Your roll number is<span className="text-blue-700 text-[18px] font-bold leading-normal"> 2023CSA0001</span>
                        </div>
                      </div>
                      <span className="text-gray-500 text-[18px] font-bold leading-normal"></span>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="absolute cursor-pointer top-1 right-2"
                  >
                    <svg
                      width="41"
                      height="46"
                      viewBox="0 0 41 46"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="style=bulk">
                        <g id="close-circle">
                          <path
                            id="vector (Stroke)_2"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.5369 15.4459C14.0861 14.8338 14.9764 14.8338 15.5256 15.4459L26.7756 27.9846C27.3247 28.5967 27.3247 29.5889 26.7756 30.201C26.2264 30.8131 25.3359 30.8131 24.7867 30.201L13.5369 17.6624C12.9877 17.0503 12.9877 16.0579 13.5369 15.4459Z"
                            fill="#1849A9"
                          />
                          <path
                            id="vector (Stroke)_3"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M26.7756 15.4459C27.3247 16.058 27.3247 17.0503 26.7756 17.6624L15.5256 30.201C14.9764 30.8131 14.086 30.8131 13.5369 30.201C12.9877 29.5889 12.9877 28.5965 13.5369 27.9844L24.7869 15.4459C25.3361 14.8338 26.2264 14.8338 26.7756 15.4459Z"
                            fill="#1849A9"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      )
      }
    </div >
  );
}
