import React from 'react';
import Validation from './Validation';

const LabelInputSmall = ({
  label,
  type,
  placeholder,
  value,
  error,
  onChange,
  width,
  widthFull,
  mandatory,
  disabled,
  readOnly,
  ...other
}) => {
  if (!width) {
    width = "400px"
  }
  width = `${width}px`

  if (widthFull) {
    width = "100%"
  }
  return (
    <div className="flex flex-col gap-1">
      <div className={`w-[300px] h-full flex justify-start`}>
        <label
          className="text-[#344054] text-[15px] font-[500]"
          htmlFor={label}
        >
          {label}{mandatory && <span className="text-[#D92D20]">*</span>}
        </label>
      </div>
      <div>
        <input
          type={type}
          name={label}
          id={label}
          className={`border ${error ? "border-[#F04438]" : "border-inputBorderGray"} text-textBlack  placeholder-textGray  outline-none font-normal rounded-lg pl-2 py-[7px] w-[300px] ${disabled && "bg-[#EEEEEE]"} ${readOnly ? "cursor-default" : "focus:border-[#1849A9]"}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          {...other}
        />
        <Validation>
          {error}
        </Validation>
      </div>
    </div>
  );
};

export default LabelInputSmall;
