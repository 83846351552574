import React, { useState, useEffect, useRef } from "react";

export default function OTPField({ emailOtp, setEmailOtp, onBlur, error, verifyOtp,sucess }) {
    const [activeEmailOtpIndex, setActiveEmailOtpIndex] = useState(null);
    const emailInputRef = useRef(null);



    const handleOnChange = (event, index, otp, setOtp, setActiveIndex) => {
        const { value } = event.target;
        const newOTP = [...otp];
        newOTP[index] = value.substring(value.length - 1, value.length);

        
        if (index > 0 && !value) {
            setActiveIndex(index - 1);
        } else if (index < otp.length - 1) {
            setActiveIndex(index + 1);
        }
        setOtp(newOTP);
        if (newOTP.join("").length === 6) {
            verifyOtp(newOTP.join(""));
        }
    };

    const handleOnKeyDown = (
        event,
        index,
        otp,
        setOtp,
        setActiveIndex
    ) => {
        const { key } = event;
        const newOTP = [...otp];
        if (key === "Backspace") {
            event.preventDefault();
            newOTP[index] = "";
            setOtp(newOTP);
            setActiveIndex(Math.max(0, index - 1));
        } else if (key === "Delete") {
            event.preventDefault();
            for (let i = index; i < otp.length; i++) {
                newOTP[i] = "";
            }
            setOtp(newOTP);
        } else if (key === "ArrowUp") {
            event.preventDefault();
            return;
        } else if (key === "ArrowDown") {
            event.preventDefault();
            return;
        } else if (key === "ArrowRight") {
            event.preventDefault();
            setActiveIndex(Math.min(otp.length - 1, index + 1));
        } else if (key === "ArrowLeft") {
            event.preventDefault();
            setActiveIndex(Math.max(0, index - 1));
        } else if (key === "Enter") {
            event.preventDefault();
        }
    };

    useEffect(() => {
        emailInputRef.current && emailInputRef.current.focus();
    }, [activeEmailOtpIndex]);


    const [showResentButton, setShowResentButton] = useState(false);

    const [timerMinutes, setTimerMinutes] = useState(0);
    const [timerSeconds, setTimerSeconds] = useState(59);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (timerSeconds > 0) {
                setTimerSeconds(timerSeconds - 1);
            }
            if (timerSeconds === 0) {
                if (timerMinutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setTimerMinutes((prev) => prev - 1);
                    setTimerSeconds(59);
                }
            }
        }, 1000);

        return () => {
            clearInterval(myInterval);
        };
    }, [timerMinutes, timerSeconds]);


    // useEffect(() => {
    //     if (timerMinutes === 0 && timerSeconds === 0) {
    //         setShowResentButton(true);
    //     }
    // }, [timerMinutes, timerSeconds]);

    // const handleResendClick = () => {
    //     setTimerMinutes(0);
    //     setTimerSeconds(59);
    //     setShowResentButton(false);
    // };

    return (
        <div className=" flex flex-col gap-1 relative">
            <label
                className="text-[#344054] text-[15px] font-[500]"
                htmlFor={"EmailOTP"}
            >
                {"Enter OTP"}
            </label>
            <div className="flex  gap-[12px] lg:gap-[12px]">
                {emailOtp.map((value, index) => (
                    <React.Fragment key={index}>
                        <input
                            ref={index === activeEmailOtpIndex ? emailInputRef : null}
                            type="number"
                            className={`w-10 h-10 border rounded-lg bg-transparent outline-none text-center text-lg font-medium transition spin-button-none ${error
                                ? "border-red-500  text-gray-900"
                                : "border-border-gray focus:border-[#1849A9] text-gray-700"
                                } text-gray-400`}
                            onChange={(e) =>
                                handleOnChange(
                                    e,
                                    index,
                                    emailOtp,
                                    setEmailOtp,
                                    setActiveEmailOtpIndex
                                )
                            }
                            onBlur={onBlur}
                            onKeyDown={(e) =>
                                handleOnKeyDown(
                                    e,
                                    index,
                                    emailOtp,
                                    setEmailOtp,
                                    setActiveEmailOtpIndex,
                                    "email"
                                )
                            }
                            value={value}
                        />
                    </React.Fragment>
                ))}
            </div>
            {error && (
                <div className="text-sm text-error-green absolute top-[70px]">
                    {error}
                </div>
            )}
            <style>
                {`
                            .spin-button-none::-webkit-outer-spin-button,
                            .spin-button-none::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                            .spin-button-none {
                                -moz-appearance: textfield;
                            }
                        `}
            </style>
        </div>
    );
}