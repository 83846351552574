import { useEffect, useState } from "react";
import Dropdown from "../component/DropDown";
import LabelInputSmall from "../component/LabelInputSmall";
import OTPField from "../component/OTPField";
import ReusableButton from "../component/ReusableButton";
import DateInput from "../component/DateInput";
import GenderInput from "../component/GenderInput";
import college_logo from "../assets/collegeNameBannerWebCorrected.svg";
import MobileNumberInput from "../component/MoblieNumberInput";
import { useNavigate, useLocation } from "react-router-dom";
import Common from "../services/api/commonApi";
import Invisible from "../component/Invisible";

export default function EnquiryForm() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.mobileNumber) {
      navigate("/");
    }
  }, []);

  const [studentName, setStudentName] = useState("");
  const [studentNameError, setStudentNameError] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dobError, setDobError] = useState("");
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [communityError, setCommunityError] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [religionError, setReligionError] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [nationalityError, setNationalityError] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateError, setStateError] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [selectedCourseCategory, setSelectedCourseCategory] = useState("");
  const [courseCategoryError, setCourseCategoryError] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseError, setCourseError] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branchError, setBranchError] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [whatsappNumberError, setWhatsappNumberError] = useState("");
  const [mobileNumber, setMobileNumber] = useState(
    location.state?.mobileNumber || ""
  );
  const [schoolName, setSchoolName] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [emailOtp, setEmailOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const [community, setCommunity] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [courseCategory, setCourseCategory] = useState([]);
  const [religionList, setReligonList] = useState([]);
  const [course, setCourse] = useState([]);
  const [branch, setBranch] = useState([]);
  const [verifiedOtp, setVerifiedOtp] = useState(false);

  // Handle Gender Change
  const handleGenderChange = (newGender) => {
    setGender(newGender);
  };

  // onSubmit Handler
  const onSubmit = () => {
    let isValid = true;

    // Student Name validation
    if (!studentName.trim()) {
      setStudentNameError("Student Name is required");
      isValid = false;
    } else {
      setStudentNameError("");
    }

    // Date of Birth validation
    if (!dateOfBirth.trim()) {
      setDobError("Date of Birth is required");
      isValid = false;
    } else {
      setDobError("");
    }

    // Gender validation
    if (!gender.trim()) {
      setGenderError("Gender is required");
      isValid = false;
    } else {
      setGenderError("");
    }

    // Community validation
    if (!selectedCommunity) {
      setCommunityError("Community is required");
      isValid = false;
    } else {
      setCommunityError("");
    }

    // Religion validation
    if (!selectedReligion) {
      setReligionError("Religion is required");
      isValid = false;
    } else {
      setReligionError("");
    }

    // Nationality validation
    if (!selectedNationality) {
      setNationalityError("Nationality is required");
      isValid = false;
    } else {
      setNationalityError("");
    }

    // District validation
    if (!selectedDistrict) {
      setDistrictError("District is required");
      isValid = false;
    } else {
      setDistrictError("");
    }

    // State validation
    if (!selectedState) {
      setStateError("State is required");
      isValid = false;
    } else {
      setStateError("");
    }

    // Pincode validation
    if (!pincode.trim()) {
      setPincodeError("Pincode is required");
      isValid = false;
    } else {
      setPincodeError("");
    }

    // Course Category validation
    if (!selectedCourseCategory) {
      setCourseCategoryError("Course Category is required");
      isValid = false;
    } else {
      setCourseCategoryError("");
    }

    // Course validation
    if (!selectedCourse) {
      setCourseError("Course is required");
      isValid = false;
    } else {
      setCourseError("");
    }

    // Branch validation
    if (!selectedBranch) {
      setBranchError("Branch is required");
      isValid = false;
    } else {
      setBranchError("");
    }

    // Whatsapp Number validation
    if (whatsappNumber.length !== 11) {
      setWhatsappNumberError("Whatsapp Number is required");
      isValid = false;
    } else {
      setWhatsappNumberError("");
    }

    if (isValid) {
      createEnquiry();
    }
  };

  async function createEnquiry() {
    try {
      const data = {
        studentName: studentName,
        dateOfBirth: dateOfBirth,
        gender: gender,
        selectedCommunity: selectedCommunity.id,
        religion: selectedReligion.id,
        selectedNationality: selectedNationality.id,
        selectedState: selectedState.name,
        selectedDistrict: selectedDistrict.name,
        pincode: pincode,
        selectedCourseCategory: selectedCourseCategory.id,
        selectedCourse: selectedCourse.id,
        selectedBranch: selectedBranch.id,
        whatsappNumber: whatsappNumber.replace(/\s/g, ""),
        mobileNumber: mobileNumber.replace(/\s/g, ""),
        schoolName: schoolName,
      };
      console.log(data);
      const createEnquiry = await Common.createEnquiry(data);
      if (createEnquiry.status === 201) {
        console.log(
          "Thank you for your enquiry. We will get back to you soon. Your application number is " +
            createEnquiry.data.nextApplicationNo +
            "."
        );
        alert(
          "Thank you for your enquiry. We will get back to you soon. Yor application number is " +
            createEnquiry.data.nextApplicationNo +
            "."
        );
        window.location.reload();
      } else {
        alert(createEnquiry.message);
      }
    } catch (error) {
      console.error("Error in createEnquiry:", error);
    }
  }

  useEffect(() => {
    const fetchCommunityData = async () => {
      try {
        const allCommunities = await Common.community();
        setCommunity(allCommunities);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchCommunityData();

    const fetchNationalityData = async () => {
      try {
        const allNationality = await Common.nationality();
        setNationality(allNationality);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchNationalityData();

    const fetchStateData = async () => {
      try {
        const allState = await Common.state();
        setState(allState);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchStateData();

    const fetchCourseCategoryData = async () => {
      try {
        const allCourseCategory = await Common.courseCategory();
        setCourseCategory(allCourseCategory);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchCourseCategoryData();

    const fetchReligionData = async () => {
      try {
        const allReligions = await Common.allReligion();
        setReligonList(allReligions.data);
      } catch (error) {
        console.error("Error fetching religion data", error);
      }
    };
    fetchReligionData();
  }, []);

  useEffect(() => {
    const fetchDistrictData = async () => {
      try {
        const allDistrict = await Common.district(selectedState.iso2);
        setDistrict(allDistrict);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchDistrictData();
  }, [selectedState]);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const allCourse = await Common.course(selectedCourseCategory.id);
        setCourse(allCourse);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchCourseData();
  }, [selectedCourseCategory]);

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const allBranch = await Common.branch(selectedCourse.id);
        setBranch(allBranch);
      } catch (error) {
        console.error("Error fetching community data", error);
      }
    };
    fetchBranchData();
  }, [selectedCourse]);

  return (
    <>
      <div className=" flex flex-col w-full h-full justify-center items-center ">
        <div className=" bg-[#1849A9] w-full h-[100px] lg:h-[150px] flex justify-center items-center ">
          <img src={college_logo} width={"750px"} alt="" />
        </div>
        <div className=" w-full h-full">
          <div className=" pb-16  pt-10 font-bold text-gray-900 text-2xl text-center ">
            Enquiry Form{" "}
          </div>
        </div>
        <div className="w-full flex flex-wrap lg:flex-nowrap lg:justify-around  lg:gap-20 justify-center items-start">
          {/* Student Name, Date of Birth, Gender */}
          <LabelInputSmall
            mandatory={true}
            label="Student Name"
            type="text"
            placeholder="Enter student name"
            value={studentName}
            error={studentNameError}
            onChange={(e) => setStudentName(e.target.value)}
          />
          <div className="pt-4 md:pt-0">
            <DateInput
              label="Date of Birth"
              mandatory={true}
              value={dateOfBirth}
              setDate={setDateOfBirth}
              date={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              error={dobError}
              placeholder="dd/mm/yyyy"
            />
          </div>
          <GenderInput
            mandatory={true}
            label="Gender"
            value={gender}
            onChange={handleGenderChange}
            error={genderError}
          />
        </div>

        <div className="w-full flex flex-wrap lg:flex-nowrap lg:justify-around  lg:gap-20 gap-5  pt-5  lg:pt-1 justify-center items-start">
          {/* Community, Religion, Nationality */}
          <Dropdown
            label="Community"
            mandatory
            data={community}
            displayValue="communityName"
            returnValue="id"
            placeholder="Select community"
            selectedOption={selectedCommunity}
            setSelectedOption={setSelectedCommunity}
            error={communityError}
          />
          <Dropdown
            label="Religion"
            mandatory
            data={religionList}
            displayValue="name"
            returnValue="id"
            placeholder="Select religion"
            selectedOption={selectedReligion}
            setSelectedOption={setSelectedReligion}
            error={religionError}
          />
          <Dropdown
            label={"Nationality"}
            mandatory={true}
            data={nationality}
            displayValue="nationalityName"
            returnValue="id"
            placeholder="Select nationality"
            selectedOption={selectedNationality}
            setSelectedOption={setSelectedNationality}
            error={nationalityError}
          />
        </div>
        <div className="w-full flex flex-wrap lg:flex-nowrap lg:justify-around mt-4  lg:gap-20 gap-5 pt-6 lg:pt-3 justify-center items-start">
          {/* State, District, Pincode */}
          <Dropdown
            label={"State"}
            mandatory={true}
            data={state}
            displayValue="name"
            returnValue="iso2"
            placeholder="Select state"
            selectedOption={selectedState}
            setSelectedOption={setSelectedState}
            error={stateError}
          />
          <Dropdown
            label={"District"}
            mandatory={true}
            data={district}
            displayValue="name"
            returnValue="id"
            placeholder="Select district"
            selectedOption={selectedDistrict}
            setSelectedOption={setSelectedDistrict}
            error={districtError}
          />
          <LabelInputSmall
            mandatory={true}
            label="Pincode"
            type="number"
            placeholder="Enter pincode"
            value={pincode}
            error={pincodeError}
            onChange={(e) => setPincode(e.target.value)}
          />
        </div>

        <div className="w-full flex flex-wrap lg:flex-nowrap lg:pt-4 lg:justify-around  pt-4 lg:gap-20 gap-5 justify-center items-start">
          {/* Course Category, Course, Branch */}
          <Dropdown
            label={"Course Category"}
            mandatory={true}
            data={courseCategory}
            displayValue="courseCategoryName"
            returnValue="id"
            placeholder="Select course category"
            selectedOption={selectedCourseCategory}
            setSelectedOption={setSelectedCourseCategory}
            error={courseCategoryError}
          />
          <Dropdown
            label={"Course"}
            mandatory={true}
            data={course}
            displayValue="courseName"
            returnValue="id"
            placeholder="Select course"
            selectedOption={selectedCourse}
            setSelectedOption={setSelectedCourse}
            error={courseError}
          />
          <Dropdown
            label={"Branch"}
            mandatory={true}
            data={branch}
            displayValue="branchName"
            returnValue="id"
            placeholder="Select branch"
            selectedOption={selectedBranch}
            setSelectedOption={setSelectedBranch}
            error={branchError}
          />
        </div>

        <div className="w-full flex flex-wrap lg:pt-7 lg:flex-nowrap lg:justify-around pt-5 lg:gap-20 gap-5 justify-center items-center">
          <LabelInputSmall
            mandatory={true}
            label="HSC - School Name"
            type="text"
            placeholder="Enter HSC - School Name"
            value={schoolName}
            // error={pincodeError}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          {/* Whatsapp Number */}
          <MobileNumberInput
            mandatory={true}
            label="Whatsapp Number"
            phoneNumber={whatsappNumber}
            setPhoneNumber={setWhatsappNumber}
            error={whatsappNumberError}
          />
          <MobileNumberInput
            mandatory={true}
            label="Mobile Number"
            phoneNumber={mobileNumber}
            // setPhoneNumber={setWhatsappNumber}
            // error={whatsappNumberError}
            disabled={true}
          />
          {/* <Invisible /> */}
          {/* <Invisible /> */}
        </div>
        <div className=" lg:h-16  h-10"></div>
        <ReusableButton
          text="Submit"
          width="170px"
          bgColor="#1849A9"
          height="40px"
          onClick={onSubmit}
          textColor="#ffffff"
          borderColor="#1849A9"
          pxPadding="8px"
        />
        <div className=" lg:h-16  h-10"></div>
      </div>
    </>
  );
}
