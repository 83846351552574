import { useRef } from "react";
import calendarIcon from "../assets/calendaricon.svg";

export default function DateInput({
  date,
  setDate,
  label,
  mandatory,
  disabled,
  readOnly,
  error,
}) {
  const dateRef = useRef();
  const handleClick = (e) => {
    if (e.target.nodeName === "INPUT") {
      return;
    }
    dateRef.current.showPicker();
  };

  const handleChange = (e) => {
    setDate(e.target.value);
  };

  const dateStyle = `
    .spin-button-none::-webkit-outer-spin-button,
    .spin-button-none::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .spin-button-none {
      -moz-appearance: textfield;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
    }
  `;

  return (
    <div>
      <div className="font-medium text-[15px] pb-1 leading-5 text-[#344054]">
        {label}{mandatory && <span className="text-[#D92D20]">*</span>}
      </div>
      <style>{dateStyle}</style>
      <div
        onClick={!disabled ? handleClick : () => { }}
        className={`flex justify-start items-center w-[300px] h-[43px] ${!disabled && "cursor-pointer"
          } gap-2 rounded-md ${!date && error ? "border-[#F04438]" : "border-inputBorderGray"
          } border px-4 pr-40 py-2 ${disabled && !readOnly && "bg-[#EEEEEE]"}`}
      >
        <img src={calendarIcon} alt="calendar" />
        <input
          id="date1"
          type="date"
          readOnly
          name="input1"
          value={date}
          onChange={handleChange}
          className={`outline-none text-base pointer-events-none border-none font-normal ${date === undefined || date === null || !date
            ? "text-textGray"
            : "text-textBlack"
            } ${disabled && !readOnly && "bg-[#EEEEEE]"}`}
        />
      </div>
      {!date && error && (
        <div className=" absolute  text-[#F04438] font-normal text-[12px] text-left">
          {error}
        </div>
      )}
      <input
        type="date"
        ref={dateRef}
        value={date}
        onChange={handleChange}
        className={`opacity-0 pointer-events-none  text-base relative -top-[5px] h-1`}
        disabled={disabled}
      />
    </div>
  );
}
