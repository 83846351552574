import Validation from './Validation';

const GenderInput = ({
  label,
  placeholder,
  value,
  error,
  onChange,
  width,
  mandatory,
  ...other
}) => {
  if (!width) {
    width = "300";
  }

  // Handle change for radio buttons
  const handleRadioChange = (e) => {
    // Call the passed onChange function with the new value
    onChange(e.target.value);
  };

  return (
    <div>
      <div className="flex flex-col gap-2 md:gap-4">
        <div className={`w-[${width}px] h-fit flex justify-start`}>
          <label
            className="text-[#344054]  text-[15px] font-[500]"
            htmlFor={label}
          >
            {label}{mandatory && (<span className="text-[#D92D20]">*</span>)}
          </label>
        </div>
        <div className="flex items-center justify-start gap-4 font-semibold text-[#667085]">
          <label className={`flex items-center gap-2 ${value === "Male" && "text-[#101828]"}`}>
            <input
              type="radio"
              name={label}
              value="Male"
              checked={value === 'Male'}
              onChange={handleRadioChange}
              {...other}
            />
            Male
          </label>
          <label className={`flex items-center gap-2 ${value === "Female" && "text-[#101828]"}`}>
            <input
              type="radio"
              name={label}
              value="Female"
              checked={value === 'Female'}
              onChange={handleRadioChange}
              {...other}
            />
            Female
          </label>
          <label className={`flex items-center gap-2 ${value === "Others" && "text-[#101828]"}`}>
            <input
              type="radio"
              name={label}
              value="Others"
              checked={value === 'Others'}
              onChange={handleRadioChange}
              {...other}
            />
            Others
          </label>
        </div>
      </div>
      {error?.length > 0 && (
        <Validation>
          {error}
        </Validation>
      )}
    </div>
  );
};

export default GenderInput;
