import React from "react";

const ReusableButton = ({
  text,
  width,
  bgColor,
  height,
  onClick,
  type,
  imgicon,
  textColor,
  borderColor,
  pxPadding,
  ...others
}) => {
  const buttonStyle = {
    width: width || "100%", // Default width is 100% if not provided
    height: height || "40px", // Default height is 40px if not provided
    backgroundColor: bgColor || "#1849A9", // Default background color if not provided
    textColor: textColor || "#ffffff", // Default text color if not provided
  };

  return (
    <div className={`flex items-center w-[${width}]`}>
      <div
        style={buttonStyle}
        className={`flex justify-between items-center rounded-lg`}
      >
        <button
          type={type || "button"}
          onClick={onClick}
          className={`flex justify-around items-center px-[${pxPadding}] w-full h-full ${borderColor && "border"} border-[${borderColor}] text-[${textColor}]  font-[500] rounded-lg`}
          {...others}
        >
          {imgicon && <img src={imgicon} alt="sign of title" />}
          {text}
        </button>
      </div>
    </div>
  );
};

export default ReusableButton;
