import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LabelInputSmall from "../component/LabelInputSmall";
import Dropdown from "../component/DropDown";
import ReusableButton from "../component/ReusableButton";
import UploadButton from "../component/UploadButton";

export default function AcademicsDetail() {
  const [prequalificationDetails, setPrequalificationDetails] = useState("");
  const [plusTwoCategory, setPlusTwoCategory] = useState("");
  const [mediumOfStudy, setMediumOfStudy] = useState("");
  const [yearOfPassingHSC, setYearOfPassingHSC] = useState("");
  const [plusTwoAttempts, setPlusTwoAttempts] = useState("");
  const [marksMaths, setMarksMaths] = useState("");
  const [marksPhysics, setMarksPhysics] = useState("");
  const [marksChemistry, setMarksChemistry] = useState("");
  const [marksCSorBio, setMarksCSorBio] = useState("");
  const [marksEnglish, setMarksEnglish] = useState("");
  const [marksLanguage, setMarksLanguage] = useState("");
  const [total, setTotal] = useState("");
  const [percentage, setPercentage] = useState("");
  const [cutOff, setCutOff] = useState("");
  const [sslcRegNo, setSSLCRegNo] = useState("");
  const [sslcOrgName, setSSLCOrgName] = useState("");
  const [sslcTotal, setSSLCTotal] = useState("");
  const [yearPassedSSLC, setYearPassedSSLC] = useState("");
  const [prequalOrgName, setPrequalOrgName] = useState("");
  const [prequalAddress, setPrequalAddress] = useState("");
  const [prequalState, setPrequalState] = useState("");
  const [prequalCountry, setPrequalCountry] = useState("");
  const [diplomaDept, setDiplomaDept] = useState("");
  const [diplomaRegNo, setDiplomaRegNo] = useState("");
  const [modeOfEducation, setModeOfEducation] = useState("");
  const [vSemesterMark, setVSemesterMark] = useState("");
  const [viSemesterMark, setVISemesterMark] = useState("");
  const [degree, setDegree] = useState("");
  // const [applicantSignature, setApplicantSignature] = useState("");
  // const [parentSignature, setParentSignature] = useState("");
  // const [documentOne, setDocumentOne] = useState(null);
  // const [documentTwo, setDocumentTwo] = useState(null);

  const [inputValues, setInputValues] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [inputValues2, setInputValues2] = useState("");
  const [selectedFiles2, setSelectedFiles2] = useState(null);

  // Dropdown Options
  const prequalificationOptions = [
    { value: "10+2", option: "10+2" },
    { value: "Diploma", option: "Diploma" },
    // ... add more options
  ];

  const plusTwoCategoryOptions = [
    { value: "Science", option: "Science" },
    { value: "Commerce", option: "Commerce" },
    { value: "Arts", option: "Arts" },
    // ... add more options
  ];

  const mediumOfStudyOptions = [
    { value: "English", option: "English" },
    { value: "Hindi", option: "Hindi" },
    { value: "Other", option: "Other" },
    // ... add more options
  ];

  const modeOfEducationOptions = [
    { value: "Regular", option: "Regular" },
    { value: "Distance", option: "Distance" },
    { value: "Online", option: "Online" },
    // ... add more options
  ];

  // ... Add more dropdown data as required

  // Handle file upload (example)
  // const handleFileUpload = (file, setFile) => {
  //   setFile(file);
  // };

  const navigate = useNavigate();
  const handleSave = () => {
    navigate("/parent");
  };

  return (
    <div className="mt-10">
      <div className="flex flex-wrap items-start justify-center w-full gap-5 px-16 lg:justify-center lg:gap-16">
        {/* Prequalification Details Dropdown */}
        <Dropdown
          label="Prequalification Details"
          mandatory={true}
          data={prequalificationOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select prequalification"
          selectedOption={prequalificationDetails}
          setSelectedOption={setPrequalificationDetails}
        />

        {/* Plus Two Category Dropdown */}
        <Dropdown
          label="+2 Category"
          data={plusTwoCategoryOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select +2 category"
          selectedOption={plusTwoCategory}
          setSelectedOption={setPlusTwoCategory}
        />

        {/* Medium of Study Dropdown */}
        <Dropdown
          label="Medium of Study"
          mandatory={true}
          data={mediumOfStudyOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Select medium of study"
          selectedOption={mediumOfStudy}
          setSelectedOption={setMediumOfStudy}
        />

        {/* Year of Passing HSC */}
        <LabelInputSmall
          label="Year of Passing HSC"
          type="text"
          placeholder="Enter Year of passing"
          value={yearOfPassingHSC}
          onChange={(e) => setYearOfPassingHSC(e.target.value)}
        />

        {/* +2 Number of Attempts */}
        <LabelInputSmall
          label="+2 Number of Attempts"
          type="text"
          placeholder="Enter +2 number of attempts"
          value={plusTwoAttempts}
          onChange={(e) => setPlusTwoAttempts(e.target.value)}
        />

        {/* Marks I (Maths) */}
        <LabelInputSmall
          mandatory={false}
          label="Marks I (Maths)"
          type="text"
          placeholder="Enter mark"
          value={marksMaths}
          onChange={(e) => setMarksMaths(e.target.value)}
        />

        {/* Marks II (Physics) */}
        <LabelInputSmall
          mandatory={false}
          label="Marks II (Physics)"
          type="text"
          placeholder="Enter mark"
          value={marksPhysics}
          onChange={(e) => setMarksPhysics(e.target.value)}
        />

        {/* Marks III (Chemistry) */}
        <LabelInputSmall
          mandatory={false}
          label="Marks III (Chemistry)"
          type="text"
          placeholder="Enter mark"
          value={marksChemistry}
          onChange={(e) => setMarksChemistry(e.target.value)}
        />

        {/* Marks IV (CS/Biology) */}
        <LabelInputSmall
          mandatory={false}
          label="Marks IV (CS/Biology)"
          type="text"
          placeholder="Enter mark"
          value={marksCSorBio}
          onChange={(e) => setMarksCSorBio(e.target.value)}
        />

        {/* Marks V (English) */}
        <LabelInputSmall
          mandatory={false}
          label="Marks V (English)"
          type="text"
          placeholder="Enter mark"
          value={marksEnglish}
          onChange={(e) => setMarksEnglish(e.target.value)}
        />

        {/* Marks VI (Language) */}
        <LabelInputSmall
          mandatory={false}
          label="Marks VI (Language)"
          type="text"
          placeholder="Enter mark"
          value={marksLanguage}
          onChange={(e) => setMarksLanguage(e.target.value)}
        />

        {/* Total */}
        <LabelInputSmall
          mandatory={false}
          label="Total"
          type="text"
          placeholder="Enter total marks"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
        />

        {/* Percentage */}
        <LabelInputSmall
          mandatory={false}
          label="Percentage"
          type="text"
          placeholder="Enter percentage"
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
        />

        {/* Cut Off (Maths/Physics/Chemistry) */}
        <LabelInputSmall
          mandatory={false}
          label="Cut Off (Maths/Phy/Chem)"
          type="text"
          placeholder="Enter cut off"
          value={cutOff}
          onChange={(e) => setCutOff(e.target.value)}
        />

        {/* SSLC Register Number */}
        <LabelInputSmall
          mandatory={false}
          label="SSLC Register Number"
          type="text"
          placeholder="Enter SSLC register number"
          value={sslcRegNo}
          onChange={(e) => setSSLCRegNo(e.target.value)}
        />

        {/* SSLC Organization Name */}
        <LabelInputSmall
          mandatory={false}
          label="SSLC Organization Name"
          type="text"
          placeholder="Enter SSLC organization name"
          value={sslcOrgName}
          onChange={(e) => setSSLCOrgName(e.target.value)}
        />

        {/* SSLC Total */}
        <LabelInputSmall
          mandatory={false}
          label="SSLC Total"
          type="text"
          placeholder="Enter SSLC total"
          value={sslcTotal}
          onChange={(e) => setSSLCTotal(e.target.value)}
        />

        {/* Year of Passed (SSLC) */}
        <LabelInputSmall
          mandatory={false}
          label="Year of Passed (SSLC)"
          type="text"
          placeholder="Enter year of passed"
          value={yearPassedSSLC}
          onChange={(e) => setYearPassedSSLC(e.target.value)}
        />

        {/* Prequalification Organization Name */}
        <LabelInputSmall
          mandatory={false}
          label="Prequalification Organization Name"
          type="text"
          placeholder="Enter organization name"
          value={prequalOrgName}
          onChange={(e) => setPrequalOrgName(e.target.value)}
        />

        {/* Prequalification Address */}
        <LabelInputSmall
          mandatory={false}
          label="Prequalification Address"
          type="text"
          placeholder="Enter prequalification address"
          value={prequalAddress}
          onChange={(e) => setPrequalAddress(e.target.value)}
        />

        {/* Prequalification State */}
        <LabelInputSmall
          mandatory={false}
          label="Prequalification State"
          type="text"
          placeholder="Enter prequalification state"
          value={prequalState}
          onChange={(e) => setPrequalState(e.target.value)}
        />

        {/* Prequalification Country */}
        <LabelInputSmall
          mandatory={false}
          label="Prequalification Country"
          type="text"
          placeholder="Enter prequalification country"
          value={prequalCountry}
          onChange={(e) => setPrequalCountry(e.target.value)}
        />

        {/* Diplome (Specific Dept) */}
        <LabelInputSmall
          mandatory={false}
          label="Diplome (Specific Dept)"
          type="text"
          placeholder="Enter department"
          value={diplomaDept}
          onChange={(e) => setDiplomaDept(e.target.value)}
        />

        {/* Diploma Register Number */}
        <LabelInputSmall
          mandatory={false}
          label="Diploma Register Number"
          type="text"
          placeholder="Enter diploma register number"
          value={diplomaRegNo}
          onChange={(e) => setDiplomaRegNo(e.target.value)}
        />

        {/* Mode of Education Dropdown */}
        <Dropdown
          label="Mode of Education"
          data={modeOfEducationOptions}
          displayValue="value"
          returnValue="option"
          placeholder="Enter mode of education"
          selectedOption={modeOfEducation}
          setSelectedOption={setModeOfEducation}
        />

        {/* V Semester Mark */}
        <LabelInputSmall
          mandatory={false}
          label="V Semester Mark"
          type="text"
          placeholder="Enter marks"
          value={vSemesterMark}
          onChange={(e) => setVSemesterMark(e.target.value)}
        />

        {/* VI Semester Mark */}
        <LabelInputSmall
          mandatory={false}
          label="VI Semester Mark"
          type="text"
          placeholder="Enter marks"
          value={viSemesterMark}
          onChange={(e) => setVISemesterMark(e.target.value)}
        />

        {/* Degree */}
        <LabelInputSmall
          mandatory={false}
          label="Degree"
          type="text"
          placeholder="Enter degree"
          value={degree}
          onChange={(e) => setDegree(e.target.value)}
        />

        {/* Applicant's Signature */}
        <UploadButton
          label={"Applicant's Signature"}
          inputValue={inputValues}
          setInputValue={setInputValues}
          selectedFile={selectedFiles}
          setSelectedFile={setSelectedFiles}
          mandatory
        />

        {/* Parent/Guardian's Signature */}
        <UploadButton
          label={"Parent/Guardian's Signature"}
          inputValue={inputValues2}
          setInputValue={setInputValues2}
          selectedFile={selectedFiles2}
          setSelectedFile={setSelectedFiles2}
        />

        {/*     <Invisible />
        <Invisible /> */}

        <div className="flex items-center gap-2 lg:gap-8 justify-center w-full ">
          <Link to={"/bank"}>
            <ReusableButton
              text="Back"
              width="140px"
              bgColor="#D92D20"
              height="40px"
              textColor="#ffffff"
              borderColor="#D92D20"
              pxPadding="8px"
            />
          </Link>
          <ReusableButton
            text="Save & Continue"
            onClick={handleSave}
            width="170px"
            bgColor="#1849A9"
            height="40px"
            textColor="#ffffff"
            borderColor="#1849A9"
            pxPadding="8px"
          />
        </div>
        <div className="h-2 lg:h-0"></div>
      </div>
    </div>
  );
}