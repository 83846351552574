import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css"
import PersonalInformation from './pages/PersonalInformation';
import BankDetails from './pages/BankDetails';
import ParentsDetails from './pages/ParentsDetails';
import PaymentGateway from './pages/PaymentGateway';
import AcademicsDetail from './pages/AcademicsDetail';
import Layout from './pages/Layout';
import TrackStatus from './pages/TrackStatus';
import TrackSuccess from './pages/TrackSuccess';
import Verify from './pages/VerifyOTP';
import TrackEnquiry from './pages/TrackEnquiry';
import EnquiryForm from './pages/EnquiryForm';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<RegistrationForm />} /> */}
        <Route path="/" element={<TrackEnquiry />} />
        <Route path="/enquiry-form" element={<EnquiryForm />} />
        {/* <Route path="/verify-otp" element={<Verify />} /> */}
        {/* <Route path="/track" element={<TrackStatus />} /> */}
        {/* <Route path="/track/status" element={<TrackSuccess />} /> */}
      </Routes>

      <Routes>
        <Route path="/personal" element={
          <Layout>
            <PersonalInformation />
          </Layout>
        } />
        <Route path="/bank" element={
          <Layout>
            <BankDetails />
          </Layout>
        } />
        <Route path='/parent' element={
          <Layout>
            <ParentsDetails />
          </Layout>
        } />
        <Route path="/payment" element={
          <Layout>
            <PaymentGateway />
          </Layout>
        } />
        <Route path="/academics" element={
          <Layout>
            <AcademicsDetail />
          </Layout>
        } />
      </Routes>
    </Router>
  );
}

export default App;


