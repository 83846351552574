import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileNumberInput from "../component/MoblieNumberInput";
import ReusableButton from "../component/ReusableButton";
import OTPField from "../component/OTPField";
import collage_logo from "../assets/collegeNameBanner.svg";
import college_logo_for_web from "../assets/collegeNameBannerWebCorrected.svg";
import bgMobile from "../assets/Frame Mobile.png";
import track from "../assets/track.svg";
import Common from "../services/api//commonApi";
import bg from "../assets/Frame 801.png";

export default function TrackEnquiry() {
  const [isValidNumber, setIsValidNumber] = useState(false);
  const navigate = useNavigate();

  // states for inputs
  const [emailOtp, setEmailOtp] = useState(new Array(6).fill(""));
  const [verifyOtp, setVerifyOtp] = useState(new Array(6).fill(""));
  const [emailOtpError, setEmailOtpError] = useState("");
  console.log(verifyOtp);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");

  // const samepleMobileNumber = "00000 00000"; //sample error
  // const sampleOtp = ['0', '0', '0', '0', '0', '0']; //sample error

  const validateMobileNumber = () => {
    if (mobileNumber.length !== 11) {
      setMobileNumberError("Enter mobile number");
      return false;
    }
    // if (mobileNumber.length === 11 && mobileNumber) { //sample error
    //   setMobileNumberError("Enter registered mobile number");
    //   return false;
    // }
    if (mobileNumber.length === 11) {
      setMobileNumberError("");
      return true;
    }
  };

  const validateEmailOTP = () => {
    if (emailOtp.includes("")) {
      setEmailOtpError("Enter OTP");
      return false;
    }
    // if (JSON.stringify(emailOtp) === JSON.stringify(sampleOtp)) {
    //   setEmailOtpError("Enter valid OTP");
    //   return false;
    // }
    else setEmailOtpError("");
    return true;
  };

  // handler-functions
  const handleGetOTP = async () => {
    try {
      if (validateMobileNumber()) {
        const formattedMobileNumber = mobileNumber.replace(/\s/g, "");
        console.log(formattedMobileNumber);
        const response = await Common.sendOtp(formattedMobileNumber);
        debugger;
        if (response.code === 200) {
          setIsValidNumber(true);
        }
      }
    } catch (error) {
      console.error("Error while getting OTP:", error);
      // Handle the error as needed
    }
  };

  const handleSubmitOTP = async () => {
    try {
      if (validateEmailOTP()) {
        const verifyOtp = await Common.verifyOtp(
          mobileNumber.replace(/\s/g, ""),
          emailOtp.join("")
        );
        if (verifyOtp.code === 200) {
          // navigate("/enquiry-form");
          navigate("/enquiry-form", { state: { mobileNumber: mobileNumber } });
        }
        if (verifyOtp.code === 401) {
          setEmailOtpError(verifyOtp.message);
        }
        if (verifyOtp.code === 402) {
          setEmailOtpError(verifyOtp.message);
          setTimeout(() => {
            setIsValidNumber(false);
            setEmailOtpError("");
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error while submitting OTP:", error);
      // Handle the error as needed
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0px 0px",
        }}
        className="  hidden lg:flex flex-col w-full h-full justify-center items-center"
      >
        <div className=" bg-[#1848a900] w-full  md:hidden  h-auto flex justify-center items-start pt-1 md:pt-[1vh]">
          <img src={collage_logo} width={"100%"} alt="" />
        </div>
        <div className=" bg-[#1849A9] w-full md:flex hidden  h-auto justify-center items-start pt-1 md:pt-[1vh]">
          <img src={college_logo_for_web} width={"auto"} alt="" />
        </div>
        <div className="flex flex-col md:flex-row mt-40 mb-20 justify-center w-full ">
          <div className="flex flex-col md:gap-3 items-center justify-center md:w-[40%]  ">
            <img src={track} alt="" className="w-[300px] md:w-[400px]" />
            <p className="w-[300px] text-center font-semibold md:font-bold md:text-lg text-white md:text-black leading-tight md:leading-[1.4]">
              Welcome to Jeppiaar Institute of Technology
            </p>
          </div>
          <div className="flex justify-center px-6 md:px-0 mt-8 md:mt-0 md:w-[60%] h-[300px] md:h-[500px]">
            <div className="md:px-10 pb-20 pt-6 md:pt-10 flex bg-white shadow-lg rounded-lg w-[600px] gap-8 md:gap-28 flex-col">
              <div className="px-4 md:px-0 w-full">
                <div className="font-bold text-xl">
                  Create / Track Your Enquiry
                </div>
                <p className="text-textGray font-medium">
                  Admission Open for 2024 - 2025
                </p>
              </div>
              <div className="flex flex-col gap-6 px-4 md:gap-16 md:px-20">
                {!isValidNumber ? (
                  <>
                    <MobileNumberInput
                      width={"100%"}
                      mandate={false}
                      label="Enter Mobile Number"
                      phoneNumber={mobileNumber}
                      setPhoneNumber={setMobileNumber}
                      error={mobileNumberError}
                    />
                    <ReusableButton
                      text={"Get OTP"}
                      textColor={"#fff"}
                      width={"100%"}
                      onClick={() => handleGetOTP()}
                    />
                  </>
                ) : (
                  <>
                    <OTPField
                      emailOtp={emailOtp}
                      setEmailOtp={setEmailOtp}
                      error={emailOtpError}
                      verifyOtp={setVerifyOtp}
                    />
                    <ReusableButton
                      text={"Submit OTP"}
                      textColor={"#fff"}
                      width={"100%"}
                      onClick={() => handleSubmitOTP()}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${bgMobile})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0px 0px",
        }}
        className=" flex flex-col lg:hidden  w-full h-full justify-center items-center"
      >
        <div className=" bg-[#1848a900] w-full  lg:hidden  h-auto flex justify-center items-start pt-1 md:pt-[1vh]">
          <img src={collage_logo} width={"100%"} alt="" />
        </div>
        <div className=" bg-[#1849A9] w-full lg:flex hidden  h-auto justify-center items-start pt-1 md:pt-[1vh]">
          <img src={college_logo_for_web} width={"auto"} alt="" />
        </div>
        <div className="flex flex-col md:flex-row mt-10 mb-20 justify-center w-full ">
          <div className="flex flex-col md:gap-3 items-center justify-center md:w-[40%]  ">
            <img src={track} alt="" className="w-[300px] md:w-[400px]" />
            <p className="w-[300px] text-center font-semibold md:font-bold md:text-lg text-white md:text-black leading-tight md:leading-[1.4]">
              Welcome to Jeppiaar Institute of Technology
            </p>
          </div>
          <div className="flex justify-center px-6 md:px-0 mt-8 md:mt-0 md:w-[60%] h-[300px] md:h-[500px]">
            <div className="md:px-10 pb-20 pt-6 md:pt-10 flex bg-white shadow-lg rounded-lg w-[600px] gap-8 md:gap-28 flex-col">
              <div className="px-4 md:px-0 w-full">
                <div className="font-bold text-xl">
                  Create / Track Your Enquiry
                </div>
                <p className="text-textGray font-medium">
                  Admission Open for 2024 - 2025
                </p>
              </div>
              <div className="flex flex-col gap-6 px-4 md:gap-16 md:px-20">
                {!isValidNumber ? (
                  <>
                    <MobileNumberInput
                      width={"100%"}
                      mandate={false}
                      label="Enter Mobile Number"
                      phoneNumber={mobileNumber}
                      setPhoneNumber={setMobileNumber}
                      error={mobileNumberError}
                    />
                    <ReusableButton
                      text={"Get OTP"}
                      textColor={"#fff"}
                      width={"100%"}
                      onClick={() => handleGetOTP()}
                    />
                  </>
                ) : (
                  <>
                    <OTPField
                      emailOtp={emailOtp}
                      setEmailOtp={setEmailOtp}
                      error={emailOtpError}
                      verifyOtp={setVerifyOtp}
                    />
                    <ReusableButton
                      text={"Submit OTP"}
                      textColor={"#fff"}
                      width={"100%"}
                      onClick={() => handleSubmitOTP()}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <footer className=" flex justify-center text-sm  items-center w-full pb-2 text-placeholder-gray">
          © 2023 Tech Hive Technology. All rights reserved.
        </footer>
      </div>
    </>
  );
}
